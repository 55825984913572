import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from "react-router-dom";

import Default from "./views/Default";
import Display from "./views/Display";
import Proctor from "./views/Proctor";
import Player  from "./views/Player";
import Tickets from "./views/Tickets";
import Order   from "./views/Order";

// disco: Gq8LPlGt5Oih72X6DYTKD
// e2e: us6Q03Pji7vVSaOT3KLgy
// athens: IQw4rPHeAPg4hBjHHenDf

var hostname = window.location.hostname.replace('www.', '');
if(hostname === 'localhost' || hostname === 'eams-staging.herokuapp.com' || hostname === 'eams-fota.herokuapp.com') {
  console.log('localhost hostname override')
  // only allow an override if on localhost
  hostname = 'display.experientialsim.com';
  // hostname = 'play.experientialsim.com';
}

const App = () => {
  // useEffect(() => {
  //   if(hostname!=='play.experientialsim.com') {
  //     window.location.href = 'https://play.experientialsim.com';
  //   }
  // }, []);

  if(hostname==='play.experientialsim.com') {
    return (
      <>
        <Helmet>
          <title>ExpSim</title>
        </Helmet>

        <Switch>
          <Route exact path="/:gameId/complete/:uuid" render={(props) => (
            <Order {...props} />
          )} />

          <Route exact path="/:gameId" render={(props) => (
            <Tickets {...props} />
          )} />

          <Route render={(props) => ( <Default {...props} /> )} />
        </Switch>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>ExpSim</title>
        </Helmet>

        <Switch>
          <Route exact path="/location/:locationId/admin" render={(props) => (
            <Proctor {...props} />
          )} />

          <Route exact path="/location/:locationId/:roleId" render={(props) => (
            <Player {...props} />
          )} />

          <Route exact path="/location/:locationId" render={(props) => (
            <Player {...props} />
          )} />

          <Route exact path="/:displayId" render={(props) => (
            <Display {...props} />
          )} />

          <Route render={(props) => ( <Default {...props} /> )} />
        </Switch>
      </>
    );
  }
}

export default App;
