// similar flag check script in both client and server folders
// client/src/utils/flag-check.js
// server/utils/flag-check.js

export const checkFlags = (item, gameTime, chapterId, playedChapters, gameFlags, activeRoles) => {
  let debug = false;
  // if(item.id === 832 && gameTime > 1020) {
  //   debug = true;
  // }

  // return true;
  if(debug) {
    console.log('---- checking flag ----');
    console.log(item.id)
    console.log(item)
    console.log(item.epsName)
    console.log(chapterId)
    console.log(chapterId + ' - ' + gameTime)
    console.log('playedChapters:', playedChapters)
    console.log('gameFlags:', gameFlags)
    console.log('activeRoles:', activeRoles)
  }

  // create a fail-safe for single-chapter games
  if(!item.chapterId) item.chapterId = 1;

  // if item chapter is not the current chapter AND
  // if item chapter is not in past played chapters, kick out
  if(!playedChapters.includes(item.chapterId) && chapterId !== item.chapterId) {
    if(debug) console.log('exit 1')
    return false;
  }

  // if the item has an endTime and we aren't in the current chapter, kick out
  if(item.endTime && chapterId!==item.chapterId) {
    if(debug) console.log('exit 2')
    return false;
  }

  // if there is a start time, and item chapter is not in the played item list
  // or if we're currently in the chapter and it's before the start time, kick out
  if(item.startTime &&
      (!playedChapters.includes(item.chapterId)) &&
      (item.chapterId === chapterId && gameTime < item.startTime)
    ) {
      if(debug) console.log('exit 3')
      return false;
    }

  // if there is an end time put the chapter has already been played, kick out
  if(item.endTime && playedChapters.includes(item.chapterId)) {
    if(debug) console.log('exit 4')
    return false;
  }

  // if there is an end time and we are in the current chapter
  // and the game time is higher than the end time, kick out
  if(item.endTime && item.chapterId === chapterId && item.endTime <= gameTime) {
    if(debug) console.log('exit 5')
    return false;
  }

  // now into flag checks
  if(item.flag) {
    // console.log('--------- FLAGS ----------')
    // console.log(item.epsName)
    let allFoundFlags = [];
    let uniqueFlags = [];

    // right now this splits by a comma to check for multiple flags
    // this may be modified later to allow for more complex flag checks
    const itemFlags = item.flag.split(',');
    if(debug) console.log('item flags:',itemFlags)

    // for each of the flags, check gameFlags to see if it exists and is active
    // if any are found, add them to allFoundFlags before moving to logic
    for (const flag of itemFlags){
      // strip whitespaces
      flag.trim();

      if(debug) {
        console.log('all flags:',gameFlags)
        console.log('current flag:' + flag)
      }
      if(gameFlags) {
        let foundFlags = gameFlags.filter(function(e) {
          // this was the original return line:
          // return (e.flag === flag && e.status === 'A');
          // but based on the actual game log that's wrong;
          // if they override the choice it removes it, not status='D'
          return (e.value === flag);
        });
        allFoundFlags = [...allFoundFlags, ...foundFlags];
      }
    }

    if(debug) {
      console.log('allFoundFlags:',allFoundFlags)
      console.log('all found flags length: '+ allFoundFlags.length)
    }

    if(item.flagType === 'SINGLE'  && allFoundFlags.length <= 0) {
      // flag type is single but none were found, kick out
      if(debug) console.log('exit 6A');
      return false;
    } else if(item.flagType === 'OR' && allFoundFlags.length <= 0) {
      // flag type was or but none were found, kick out
      if(debug) console.log('exit 6B');
      return false;
    } else if(item.flagType === 'NOT' && allFoundFlags.length > 0) {
      // flag type was NOT, but some were found, kick out
      if(debug) console.log('exit 6C');
      return false;
    }

    // if we are looking for multiple flags, we need to get the unique values
    if(itemFlags.length > 1) {
      // then get unique values of the found flags so we don't have duplicates
      // e.g. in a vote we don't want to count VOTE001_A mulitple times
      uniqueFlags = allFoundFlags.filter((v, i, a) => a.indexOf(v) === i);
      if(debug) console.log(uniqueFlags)

      if(item.flagType === 'AND' && itemFlags.length !== uniqueFlags.length) {
        if(debug) {
          console.log('itemFlags length:',itemFlags.length)
          console.log('uniqueFlags length:',uniqueFlags.length)
          console.log('exit 7');
        }
        return false;
      }
    }
  }

  // if we've gotten here, all criteria met
  if(debug) console.log('exiting true')
  return true;
}
