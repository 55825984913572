import React, { Fragment, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';

import MemoryGame from "./MemoryGame";
import Anagram from "./Anagram";
import ImageMap from "./ImageMap";




// lklklk this is functioning but you'll need to add in the
// confirmation modal if the duration is not Full or null




const View = (props) => {
  const debug = false;
  const socket = props.socket;
  const locationId = props.locationId;
  const gameObj = props.gameObj;
  const gameFlags = props.gameFlags;
  const gameTime = props.gameTime;
  const roleObj = props.roleObj;
  const simRoles = props.simRoles;
  const item = props.item;

  const [show, setShow] = useState(false);
  const toggle = () => setShow((s) => !s);

  const flagRef = useRef({});

  const [toSelect, setToSelect] = useState([]);
  const [personSelected, setPersonSelected] = useState(false);
  const [myLoc, setMyLoc] = useState();
  const [alreadyTaken, setAlreadyTaken] = useState([]);

  useEffect(() => {
    if(!gameObj || !gameFlags) return;
    if(item?.template!=='with-user') return;
    if(debug) {
      console.log(gameObj);
      console.log(gameFlags);
    }
    
    // hardcoded...
    let tagId = 9; // athenian tag
    let voteIds = [83, 83, 77, 78, 79, 74, 75, 76, 80, 81, 82, 90, 92];
    let ids = [];

    // first determine which ones are Athenians, and active in the game
    ids = Object.fromEntries(Object.entries(gameObj.tags).filter(([key, value]) => value.includes(tagId)));
    ids = Object.fromEntries(Object.entries(ids).filter(([key, value]) => gameObj.activeRoles.includes(parseInt(key))));
    ids = Object.keys(ids).map(x => parseInt(x));

    // then check where my ... location is?
    let myLoc = gameFlags.find(x => x.roleId===roleObj.role.id && voteIds.includes(x.actionId));
    console.log(myLoc);
    console.log(item);
    if(!myLoc) return;
    setMyLoc(myLoc?.value);

    let targets = gameFlags.filter(x => ids.includes(x.roleId) && x.value===myLoc?.value);
    let targetIds = targets?.map(x => x.roleId);
    let finalList = simRoles.filter(x => targetIds.includes(x.id));

    if(debug) {
      console.log('athenian ids:',ids);
      console.log(myLoc);
      console.log(targets);
      console.log(targetIds);
      console.log(finalList);
    }
    setToSelect(finalList);
  }, [debug, item, gameObj, gameFlags, roleObj, simRoles])

  useEffect(() => {
    if(!item) return;
    let taken = gameFlags.filter(x => x.actionId===item?.id);
    let takenIds = taken.filter(x => x.recipientId).map(x => x.recipientId);
    // console.log('takenIds', takenIds);
    setAlreadyTaken(takenIds);

    if(takenIds.includes(personSelected)) {
      setPersonSelected(null);
      flagRef.current = null;
      setShow(false);
    }
  }, [item, gameFlags, personSelected])

  const fileFlag = (e, flag) => {
    console.log('file new flag: ',flag);
    console.log('gameId:',gameObj.id);
    if(item?.template==='with-user') flag.recipientId = personSelected;

    if(item?.executionType === 'Confirmed') {
      flagRef.current = flag;
      setShow(true);
    } else {
      socket.emit('clientmsg', { roomId: locationId, gameId: gameObj.id, flag });
    }
  }

  const confirm = () => {
    setShow(false);
    socket.emit('clientmsg', { roomId: locationId, gameId: gameObj.id, flag: flagRef.current });
  }

  let timeRemaining = item.endTime - gameTime;
  timeRemaining = new Date(timeRemaining * 1000).toISOString().substr(14, 5)

  if(item?.template==='anagram') {
    let content = item.content.replace('<p>', '');
    content = content.replace('</p>', '');
    content = JSON.parse(content);

    return (
      <div>
        <div className="float-end">{timeRemaining}</div>
        <p>Solve the sentence by rearranging the below letters.</p>
        {content.map((word, key) => (
          <Anagram key={`anagram${key}`} word={word} />
        ))}
      </div>
    );

  } else if(item?.template==='image-map') {
    let imageMap = item?.puzzle?.replace('<p>', '');
    imageMap = imageMap.replace('</p>', '');

    try {
      imageMap = JSON.parse(imageMap);
    } catch(e) {
      imageMap = {start:[],endType:null,endValue:null};
    }

    return (
      <div>
        <div className="float-end">{timeRemaining}</div>
        <ImageMap map={imageMap} />
      </div>
    )

  } else if(item?.template==='memory') {
    let game = item?.puzzle?.replace('<p>', '');
    game = game.replace('</p>', '');

    try {
      game = JSON.parse(game);
    } catch(e) {
      game = {cards:[],cardBack:null,endType:null,endValue:null};
    }

    return (
      <div>
        <div className="float-end">{timeRemaining}</div>
        <MemoryGame game={game} />
      </div>
    )
  } else {
    // remove action items; will need to figure out how to replace it with optionHtml later
    let content = item.content.replace('<p><span>Action Options</span><br /></p>', '');
    content = content.replace('<p><span data-redactor-type="variable">Action Options</span></p>', '');
    content = content.replace('<span data-redactor-type="variable">Action Options</span>', '');

    let optionHtml = item.options.filter(obj => obj['option-text'] && obj['option-flag']).map((opt,idx) => {
      let activeBtn = '';
      // it's only active for them if THEY have selected
      let foundFlags = gameFlags.filter(function(e) {
        return (e.value===opt['option-flag'] && e.roleId===roleObj.role.id);
      });

      if(foundFlags.length > 0)
        activeBtn = 'active';

      // lklklk add disabled if it's paused?

      return (
        <button key={ 'option-'+item.id+'-' + idx.toString() } className={"btn btn-lg btn-primary d-block mx-auto mb-3 " + activeBtn}
          onClick={(e) => { fileFlag(e, {roleId: parseInt(roleObj.role.id), actionId: item.id, value: opt['option-flag']}) }}
          disabled={item?.template==='with-user' && !personSelected}>
            {parse(opt['option-text'])}
        </button>
      )
    })

    if(myLoc) {
      if(myLoc==='04_Pnyx' && item.id!==84) return null;
      if(myLoc==='04_Docks' && item.id!==85) return null;
      if(myLoc==='04_Acropolis' && item.id!==86) return null;
      if(myLoc==='04_Agora' && item.id!==87) return null;
      if(myLoc==='04_Walls' && item.id!==88) return null;
      if(myLoc==='04_Gate' && item.id!==89) return null;
    }

    return (
      <>
        <div className="float-end">{timeRemaining}</div>
        { debug && ( <strong>{item.id} - {item.title} - {item.startTime} - {item.endTime} - {item.flagType} - {item.flag}</strong> )}
        {parse(content)}

        {item?.template==='with-user' && (
          <Fragment>
            <h4>Select the person:</h4>
            { toSelect.length===0 && <p className="text-muted">No options yet.</p>}
            { toSelect.map(obj => {
              let whoops = alreadyTaken.includes(obj.id);
              return (
                <button key={obj.id} className={personSelected===obj.id ? `btn btn-success` : whoops ? `btn btn-danger` : `btn btn-outline-dark`} onClick={() => setPersonSelected(obj.id)} disabled={whoops}>
                  {obj.roleName}
                </button>
              )
            })}


            <h4 className="mt-4">Select your action:</h4>
            {optionHtml}
            <br />
            <br />
            <br />
          </Fragment>
        )}

        {item?.template!=='with-user' && optionHtml}


        <Modal show={show} size="sm" onHide={toggle} backdrop={true} keyboard={true} scrollable centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Decision</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to make this decision?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-danger" onClick={toggle}>Cancel</button>
            <button className="btn btn-outline-success" onClick={confirm}>Confirm</button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default View;
